import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import * as React from 'react';
import Menu from '../Menu/Menu';
import MenuItem from '../Menu/MenuItem/MenuItem';

type IProps = { 
  title: string,
  imgPath: string,
  location: string,
  mainInfo: string,
  price: number,
}

export default class DetailedCard extends React.PureComponent<IProps, {}> {
  public render() {
    const {
      title,
      imgPath,
      location,
      mainInfo,
      price
    } = this.props;

    return (
    <div className='detailed-card'>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          height="194"
          image={imgPath}
        />
        <CardContent>
          <div>Reviews</div>
          <div className='title'>{title}</div>
          <div className='location'>
            <img src='/assets/location-pin.png'/>
            <span>{location}</span>
          </div>
          <div className='main-info'>{mainInfo}</div>
          <div className='price'>{`€${price}`}<span>/night</span></div>
        </CardContent>
      </Card>
    </div>
    );
  }
} 
