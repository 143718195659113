import * as React from 'react';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import MenuItem from '../Menu/MenuItem/MenuItem';

type IProps = { 
  children?: React.ReactNode 
}

export default class Layout extends React.PureComponent<IProps, {}> {
  private getNavigationItems() {
    return (
      <>
        <MenuItem text="Home" onClick={() => null}/>
        <MenuItem text="Places" onClick={() => null}/>
        <MenuItem text="Explore" onClick={() => null}/>
        <MenuItem text="Help" onClick={() => null}/>
        <MenuItem text="About" onClick={() => null}/>
      </>
    );
  }

  public render() {
    const {
      children,
    } = this.props;

    return (
      <div className="layout">
        <div className="layout-contents">
          {/* <Menu>
            {this.getNavigationItems()}
          </Menu> */}
          {children}
          <Footer />
        </div>
      </div>
    );
  }
} 
