import React from "react";

type IProps = {
    text: string,
    onClick: Function
}

export default class MenuItem extends React.PureComponent<IProps, {}> {
    public render() {
        const { text, onClick } = this.props;
        return (
            <button
                type="button" 
                className="menu-item"
                onClick={() => onClick()}
                >
                    <div className="label">{text}</div>
            </button>
        )
    }
}

