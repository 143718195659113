import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import * as React from 'react';
import Menu from '../Menu/Menu';
import MenuItem from '../Menu/MenuItem/MenuItem';

type IProps = { 
  title: string,
  imgPath: string,
  buttonText?: string
}

export default class SimpleCard extends React.PureComponent<IProps, {}> {
  public render() {
    const {
      title,
      imgPath,
      buttonText
    } = this.props;

    return (
      <div className='simple-card'>
        <Card>
          <CardMedia
            component="img"
            className='card-img'
            height="450"
            image={ imgPath }
          />
          <CardContent>
            <Typography 
              className="card-title" 
              sx={{ marginTop: -9 }} 
              component="div"
            >
              { title }
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }
} 
