import * as React from 'react';

export default class Footer extends React.PureComponent<{}, {}> {
  public render() {

    return (
      <div className="footer">
        <div className='footer-content'>
          <div className="footer-bar">
            <img className='logo' src='/assets/breez-logo.svg' />
          </div>
          <div className="footer-bar">
          <h3>Legal</h3>
            <div className='footer-links'>
            <a href="/">Terms of Service</a>
            <a href="/">Privacy Policy</a>
            </div>
          </div>
          <div className="footer-bar">
            <h3>Links</h3>
              <div className='footer-links'>
              <a href="/">Home</a>
              <a href="/">Places</a>
              <a href="/">Explore</a>
              <a href="/">About</a>
              </div>
          </div>
          <div className="footer-bar">
            <h3>Help and Contact</h3>
              <div className='footer-links'>
              <a href="/">Help Center</a>
              <p>+383 43 333 222</p>
              <a href="/">breeztravel@gmail.com</a>
              <p>7323 E. Newcastle Street
                  Huntsville, AL 35803</p>
              </div>
          </div>
          <div className="footer-bar">
            <h3>Follow us</h3>
            <div className='footer-links'>
              <div className='follow-section'>
                <img src='/assets/facebook-icon.png'/>
                <img src='/assets/instagram-icon.png'/>
                <img src='/assets/linkedin-icon.png'/>
              </div>
            </div>
          </div>
        </div>
        <hr className='line-break' />
        <p className='copyright'>© Copyright 2022 Breez Travel & More</p>
      </div>
    );
  }
} 
