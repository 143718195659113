import Layout from '../Shared/Layout/Layout';
import HomePage from './HomePage/HomePage';


const Home = () => (
  <div>
    <Layout>
      <HomePage></HomePage>
    </Layout>
  </div>
);

export default (Home);
