import { Card, CardContent, CardMedia, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { Animated } from "react-animated-css";
import DetailedCard from "../../Shared/Cards/DetailedCard";
import SimpleCard from "../../Shared/Cards/SimpleCard";
import Input from "../../Shared/Input/Input";

interface IState {
    searchContentType: number
}

type IProps = {};


class HomePage extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            searchContentType: 1
        };
    }

    updateSeachContentType(type: number) {
        this.setState({
            searchContentType: type
        })
    }

    public render() {
        const Item = styled(Paper)(({ theme }) => ({
            backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            ...theme.typography.body2,
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
          }));
          
        const { searchContentType } = this.state;
        return (
            <div className="home-wrapper">
                <div className="hero-wrapper">
                    <div className="hero-overlay"></div>
                    <img src="/assets/hero.png" />
                    <div className="hero-text">
                        <h2>Explore and experience the nature of Kosovo</h2>
                        <p>Begin your adventure in Kosovo by booking a place in mountainous wonderlands</p>
                    </div>
                </div>

                {/* <div className="multi-search-wrapper">
                    <h1 className="section-title">Search for places</h1>
                    <div className="multi-search-header">
                        <div className="multi-search-tabs">
                            <div className={`multi-search-tab ${ searchContentType === 1 && ' active' }`}>
                                <a 
                                    onClick={() => this.updateSeachContentType(1)}>
                                        Villas & Hotels
                                </a>
                            </div>
                            <div className={`multi-search-tab ${ searchContentType === 2 && ' active' }`}>
                                <a 
                                    onClick={() => this.updateSeachContentType(2)}>
                                        Restaurants
                                </a>
                            </div>
                            <div className={`multi-search-tab ${ searchContentType === 3 && ' active' }`}>
                                <a 
                                    onClick={() => this.updateSeachContentType(3)}>
                                        Night Clubs
                                </a>
                            </div>
                        </div>
                        <div className="multi-search-line"></div>
                    </div>

                    <div className="multi-search-content">
                    {
                        this.state && searchContentType === 1 &&
                        <>
                            <div>
                                <Input 
                                    inputType="text"
                                    label="Where do you want to go?"
                                    name="location"
                                    onChange={() => null}
                                    placeholderText="Location"
                                />
                            </div>
                            <div>
                                <Input 
                                    inputType="text"
                                    label="Add a date"
                                    name="startDate"
                                    onChange={() => null}
                                    placeholderText="Check In"
                                />
                            </div>
                            <div>
                                <Input 
                                    inputType="text"
                                    label="Add a date"
                                    name="endDate"
                                    onChange={() => null}
                                    placeholderText="Check Out"
                                />
                            </div>
                            <div>
                                <Input 
                                    inputType="text"
                                    label="Add guests"
                                    name="guest"
                                    onChange={() => null}
                                    placeholderText="Guests"
                                />
                            </div>
                        </>
                    }
                            
                    {
                        this.state && searchContentType === 2 &&
                        <>
                            <p>This is the 2nd tab</p>
                        </>
                    }
                    </div>
                </div> */}

{/* 
                <div className="mask-wrapper">
                    <div className="mask-image-wrapper">
                    <div className="mask-text-wrapper">
                        <h2>Explore majestic landscapes</h2>
                        <p>Kosovo has a variety of breathtaking  landscapes which you can visit whilst enjoying your stay in a cozy vila or a comfy hotel.</p>
                    </div>
                        <img className="slide-image" src="/assets/mask-image-1.png" />
                        <img className="mask" src="/assets/villa-mask.png" />
                    </div>

                </div> */}
                
                <div className="main-cards-wrapper">
                    <h1 className="section-title">Enjoy the comfort of villas and hotels</h1>

                    <div className="main-cards-container">
                        <SimpleCard 
                                title="Villas"
                                imgPath="/assets/villa.png" 
                                buttonText="Search villas" />
                        <SimpleCard
                                title="Hotels"
                                imgPath="/assets/hotel.png" 
                                buttonText="Search hotels" />
                    </div>

                    <h1 className="section-title">Fancy a delightful dinner or revel in an ecstatic night</h1>

                    <div className="main-cards-container">
                        <SimpleCard 
                                    title="Restaurants" 
                                    imgPath="/assets/restaurant.png" 
                                    buttonText="Search villas" />
                        <SimpleCard 
                                    title="Night Clubs" 
                                    imgPath="/assets/club.png" 
                                    buttonText="Search hotels" />
                    </div>
                </div>

                <div className="recommendations-wrapper">
                    <h1 className="section-title">Popular places to stay</h1>

                    <div className="recommendations-container">
                    <DetailedCard 
                            title="Villa Breza Cozy Wooden Retreat in Sharr Mountains" 
                            imgPath="/assets/recommend1.png" 
                            location="Brezovica"
                            mainInfo="8 guests   4 bedrooms   2 baths" 
                            price={220}/>

                    <DetailedCard 
                            title="Vila AMARONE - Your home in the mountains." 
                            imgPath="/assets/recommend2.png" 
                            location="Brezovica"
                            mainInfo="5 guests   2 bedrooms   1 baths" 
                            price={88}/>

                    <DetailedCard 
                            title="De La Villa Brezovice" 
                            imgPath="/assets/recommend3.png" 
                            location="Brezovica"
                            mainInfo="8 guests   3 bedrooms   1 baths" 
                            price={180}/>
                    </div>

                    <div className="show-more-btn">
                        <button >Show more</button>
                    </div>
                </div>

                <div className="help-section">
                    <div className="help-text">
                        <h1>Have any questions?</h1>
                        <p>If you need any help or have any question related to our platform feel free to contact us.</p>
                    </div>
                    <div className="help-buttons">
                        <button>Contact</button>
                        <button>Help Center</button>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default (HomePage);
