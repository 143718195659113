import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import Home from './components/Home/Home';
import './App.scss';

class App extends React.PureComponent<{}, {}> {
    public render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/'
                        element={<Home/>}>
                    </Route> 
                </Routes>
            </BrowserRouter>
        )
    }
}

export default App;